frappe.provide("martin_bros.utils");

martin_bros.utils.convert_length = function (input) {
	let feet = 0;
	let inches = 0;

	input = input.trim();

	if (!isNaN(input)) {
		if (input.includes(".")) {
			// If the input contains a decimal point, split into feet and inches
			let parts = input.split(".");
			feet = parseInt(parts[0], 10);
			inches = Math.round(parseFloat("0." + parts[1]) * 12);

			if (inches >= 12) {
				feet += Math.floor(inches / 12);
				inches = inches % 12;
			}
		} else {
			// If it's an integer, handle as feet and inches (e.g., "806" -> 8'6")
			if (input.length > 2) {
				feet = parseInt(input.slice(0, -2), 10);
				inches = parseInt(input.slice(-2), 10);
			} else {
				inches = parseInt(input, 10);
			}
		}
	} else {
		// Case 2: Input includes feet and inches symbols ("' and ")
		let feetInchPattern = /^(\d+)'(\d*\.?\d*)"?$/;
		let match = feetInchPattern.exec(input);

		if (match) {
			feet = parseInt(match[1], 10);
			inches = parseInt(match[2] || 0, 10);
		} else {
			frappe.throw(__("Invalid input format"));
		}
	}

	if (inches >= 12) {
		frappe.throw(__("Inches should be less than 12"));
	}

	if (isNaN(feet) || isNaN(inches)) {
		frappe.throw(__("Feet or inches are not numeric"));
	}
	return {
		feet: feet,
		inches: inches,
		total_inches: feet * 12 + inches,
	};
};
