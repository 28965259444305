frappe.provide("martin_bros.utils");

martin_bros.utils.emp_verification = function (doc, call_func, args) {
	const change_event = (dialog) => {
		if (!dialog.values_setup) return;
		dialog.set_value("is_information_is_correct", "No");
		if (!doc.is_report) dialog.disable_primary_action();
	};
	const create_info_verification = (values) => {
		frappe.call({
			method: "martin_bros.mb_hr.doctype.emp_info_verification.emp_info_verification.create_employee_verification",
			args: values,
			callback(res) {
				if (!res.exc) {
					frappe.show_alert({
						message: "Employee info verification request added successfully",
						indicator: "green",
					});
					if (call_func) {
						call_func(args);
					}
					verification_dialog.hide();
				}
			},
		});
	};
	const verification_dialog = new frappe.ui.Dialog({
		title: __("Emp Info Verification"),
		size: "large",
		fields: [
			{
				fieldname: "employee",
				fieldtype: "Link",
				label: "Employee",
				options: "Employee",
				read_only: 1,
			},
			{
				fetch_from: "employee.status",
				fieldname: "employee_status",
				fieldtype: "Data",
				label: "Employee Status",
				read_only: 1,
			},
			{
				fetch_from: "employee.custom_address_1",
				fieldname: "address_1",
				fieldtype: "Data",
				label: "Address 1",
				onchange() {
					change_event(verification_dialog);
				},
			},
			{
				fetch_from: "employee.custom_address_2",
				fieldname: "address_2",
				fieldtype: "Data",
				label: "Address 2",
				onchange() {
					change_event(verification_dialog);
				},
			},
			{
				fetch_from: "employee.custom_city",
				fieldname: "city",
				fieldtype: "Link",
				label: "City",
				options: "City",
				onchange() {
					change_event(verification_dialog);
				},
			},
			{
				fetch_from: "employee.custom_zip",
				fieldname: "zip",
				fieldtype: "Link",
				label: "ZIP",
				options: "ZIP",
				onchange() {
					change_event(verification_dialog);
				},
			},
			{
				fetch_from: "employee.custom_state",
				fieldname: "state",
				fieldtype: "Link",
				label: "State",
				options: "State",
				read_only: 1,
			},
			{
				fieldtype: "Column Break",
			},
			{
				fetch_from: "employee.personal_email",
				fieldname: "email_id",
				fieldtype: "Data",
				label: "Email ID",
				read_only: 1,
			},
			{
				fetch_from: "employee.custom_work_phone",
				fieldname: "phone",
				fieldtype: "Data",
				label: "Phone",
				read_only: 1,
			},
			{
				fetch_from: "employee.cell_number",
				fieldname: "cell",
				fieldtype: "Data",
				label: "Cell",
				read_only: 1,
			},
			{
				fetch_from: "employee.custom_trade",
				fieldname: "trade",
				fieldtype: "Link",
				label: "Trade",
				options: "Trade",
				read_only: 1,
			},
			{
				fieldname: "stage",
				fieldtype: "Link",
				label: "Stage",
				options: "Stage",
				read_only: 1,
			},
			{
				fieldname: "is_information_is_correct",
				fieldtype: "Select",
				label: "Is Information is correct ",
				options: "\nYes\nNo",
				read_only: 1,
			},
		],
		on_page_show() {
			this.values_setup = false;
			frappe
				.call({
					method: "martin_bros.mb_hr.customizations.employee.employee.get_employee_details",
					args: {
						employee: doc.employee,
						union_reporting_region: doc.union_reporting_region,
					},
					callback(r) {
						verification_dialog.set_values(r.message);
					},
				})
				.then(() => {
					this.values_setup = true;
					this.dirty = false;
				});
		},
	});
	let info_label = __("Information is correct");
	const info_action = () => {
		if (verification_dialog.values_setup && verification_dialog.dirty) return;
		let values = verification_dialog.get_values();
		values["is_information_is_correct"] = "Yes";
		create_info_verification(values);
	};
	let save_label = __("Save & Notify");
	const save_action = () => {
		if (!verification_dialog.dirty) {
			frappe.show_alert({
				message: __("No changes made"),
				indicator: "yellow",
			});
			return;
		}
		let values = verification_dialog.get_values();
		values["is_information_is_correct"] = "No";
		create_info_verification(values);
	};
	if (doc.is_report) {
		verification_dialog.set_primary_action(save_label, save_action);
	} else {
		verification_dialog.set_primary_action(info_label, info_action);
		verification_dialog.set_secondary_action(save_action);
		verification_dialog.set_secondary_action_label(save_label);
	}
	verification_dialog.show();
};

martin_bros.utils.verify_hours_input = (frm, fieldname) => {
	let value = frm.doc[fieldname];
	let value_rounded = parse_hours_input(value);
	frm.set_value(fieldname, value_rounded);
};

function parse_hours_input(value) {
	if (value) {
		const value_rounded = Math.round(value * 4) / 4;
		if (value_rounded !== value) {
			frappe.show_alert({
				message: "Hours field can have values in multiple of 0.25 (15 mins)",
				indicator: "yellow",
			});
			return value_rounded;
		}
	}
	return value || 0;
}

martin_bros.utils.parse_hours_input = parse_hours_input;
