frappe.provide("frappe.ui.toolbar");

$(document).ready(function () {
	var formElement = $(".navbar-collapse form");
	if (formElement.length > 0) {
		let btn = `<button type='button' class='btn btn-primary set-session-defaults'
        onclick='frappe.ui.toolbar.setup_session_defaults()'>Session Defaults</button>`;
		$(btn).insertAfter(formElement);
	}
});

frappe.ui.toolbar.setup_session_defaults = function () {
	let fields = [];
	frappe.call({
		method: "frappe.core.doctype.session_default_settings.session_default_settings.get_session_default_values",
		callback: function (data) {
			fields = JSON.parse(data.message);
			// add filter for project
			let is_company_in_fields = fields.find((field) => field.fieldname === "company");
			fields.forEach(function (d) {
				if (d.fieldname == "project") {
					if (is_company_in_fields) {
						d.get_query = function () {
							return {
								filters: {
									company: cur_dialog.get_value("company"),
								},
							};
						};
					}
				}
			});
			let perms = frappe.perm.get_perm("Session Default Settings");
			//add settings button only if user is a System Manager or has permission on 'Session Default Settings'
			if (frappe.user_roles.includes("System Manager") || perms[0].read == 1) {
				fields[fields.length] = {
					fieldname: "settings",
					fieldtype: "Button",
					label: __("Settings"),
					click: () => {
						frappe.set_route(
							"Form",
							"Session Default Settings",
							"Session Default Settings"
						);
					},
				};
			}
			frappe.prompt(
				fields,
				function (values) {
					//if default is not set for a particular field in prompt
					fields.forEach(function (d) {
						if (!values[d.fieldname]) {
							values[d.fieldname] = "";
						}
					});
					frappe.call({
						method: "frappe.core.doctype.session_default_settings.session_default_settings.set_session_default_values",
						args: {
							default_values: values,
						},
						callback: function (data) {
							if (data.message == "success") {
								frappe.show_alert({
									message: __("Session Defaults Saved"),
									indicator: "green",
								});
								frappe.ui.toolbar.clear_cache();
							} else {
								frappe.show_alert({
									message: __(
										"An error occurred while setting Session Defaults"
									),
									indicator: "red",
								});
							}
						},
					});
				},
				__("Session Defaults"),
				__("Save")
			);
		},
	});
};
