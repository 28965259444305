frappe.provide("martin_bros.filters");

martin_bros.filters.apply_project_and_ncw_filters = function (frm, ncw_field, phase_field) {
	frm.set_query("project", () => ({
		filters: {
			company: frm.doc.company,
		},
	}));
	frm.set_query(ncw_field, () => ({
		filters: {
			project: frm.doc.project,
			completed: 0,
		},
	}));
	frm.set_query(phase_field, () => ({
		filters: {
			project: frm.doc.project,
		},
	}));
};
