frappe.provide("martin_bros.utils");

let dialog;

martin_bros.utils.advance_item_search = function (frm, table_fieldname) {
	dialog = new frappe.ui.Dialog({
		title: "Select Item",
		fields: getDialogFields(),
		primary_action_label: "Add",
		primary_action(values) {
			handle_primary_action(frm, table_fieldname, values);
			dialog.hide();
		},
	});
	dialog.show();
};

function getDialogFields() {
	const ssmaFields = ["width", "flange", "gauge"].map(create_ssma_fields);
	return [
		{
			label: "Selection Type",
			fieldname: "type",
			fieldtype: "Select",
			options: "String\nMaster\nQuick Pick\nSSMA",
		},
		...ssmaFields,
		{
			label: "Item Group",
			fieldname: "item_group",
			fieldtype: "Link",
			options: "Item Group",
			depends_on: "eval: doc.type == 'Master'",
			onchange: function () {
				fetch_is_group("item_group", "is_group");
			},
		},
		create_is_group_fields("is_group"),
		create_subgroup_fields("item_subgroup_1", "is_group_1", "is_group", "item_group"),
		create_is_group_fields("is_group_1"),
		create_subgroup_fields("item_subgroup_2", "is_group_2", "is_group_1", "item_subgroup_1"),
		create_is_group_fields("is_group_2"),
		create_subgroup_fields("item_subgroup_3", "is_group_3", "is_group_2", "item_subgroup_2"),
		create_is_group_fields("is_group_3"),
		{
			label: "Quick Pick",
			fieldname: "quick_pick",
			fieldtype: "Link",
			options: "Quick Pick",
			depends_on: "eval: doc.type == 'Quick Pick'",
		},
		{
			label: "Item",
			fieldname: "item",
			fieldtype: "Link",
			options: "Item",
			depends_on:
				"eval: doc.type == 'SSMA' || (doc.type == 'Quick Pick' && doc.quick_pick) || doc.type == 'String' || (doc.item_group && !doc.is_group) || (doc.item_subgroup_1 && !doc.is_group_1) || (doc.item_subgroup_2 && !doc.is_group_2) || (doc.item_subgroup_3 && !doc.is_group_3)",
			get_query: function () {
				return get_item_filter();
			},
		},
	];
}

function create_ssma_fields(fieldname) {
	return {
		label: frappe.unscrub(fieldname),
		fieldname: fieldname,
		fieldtype: "Data",
		depends_on: "eval: doc.type == 'SSMA'",
	};
}

function create_is_group_fields(fieldname) {
	return {
		label: frappe.unscrub(fieldname),
		fieldname: fieldname,
		fieldtype: "Check",
		hidden: true,
	};
}

function create_subgroup_fields(fieldname, group_fieldname, depends_on_fieldname, filter_field) {
	return {
		label: frappe.unscrub(fieldname),
		fieldname: fieldname,
		fieldtype: "Link",
		options: "Item Group",
		onchange: function () {
			fetch_is_group(fieldname, group_fieldname);
		},
		get_query: function () {
			return get_item_group_filter(filter_field);
		},
		depends_on: `eval: doc.type == 'Master' && doc.${depends_on_fieldname} == 1`,
	};
}

function fetch_is_group(fieldname, is_group_field) {
	if (dialog) {
		frappe.db.get_value(
			"Item Group",
			dialog.get_value(fieldname),
			"is_group",
			function (message) {
				dialog.set_value(is_group_field, message.is_group);
			}
		);
	}
}

function get_item_group_filter(parentField) {
	if (dialog) {
		return { filters: { parent_item_group: dialog.get_value(parentField) } };
	}
	return {};
}

function get_item_filter() {
	if (dialog) {
		return {
			query: "martin_bros.mb_stock.customizations.material_request.material_request.get_item_filters",
			filters: {
				search_type: dialog.get_value("type"),
				item_group:
					dialog.get_value("item_subgroup_3") ||
					dialog.get_value("item_subgroup_2") ||
					dialog.get_value("item_subgroup_1") ||
					dialog.get_value("item_group"),
				quick_pick: dialog.get_value("quick_pick"),
				width: dialog.get_value("width"),
				flange: dialog.get_value("flange"),
				gauge: dialog.get_value("gauge"),
			},
		};
	}
	return {};
}

function handle_primary_action(frm, table_fieldname, values) {
	if (values.item) {
		if (frm.doc[table_fieldname].length && !frm.doc[table_fieldname][0].item_code) {
			frm.set_value(table_fieldname, []);
		}
		let child = frm.add_child(table_fieldname);
		frappe.model.set_value(child.doctype, child.name, "item_code", values.item);
		frm.refresh_field(table_fieldname);
	}
}
