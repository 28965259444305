import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

frappe.provide("martin_bros");

martin_bros.excelToPdf = async function excelToPdf(file, filename) {
	const workbook = new ExcelJS.Workbook();
	await workbook.xlsx.load(file);
	const worksheet = workbook.getWorksheet(1);
	let pageContent = [];
	pageContent.push(await map_excel_to_pdf(worksheet));
	const desc_sheet = workbook.getWorksheet(2);
	if (desc_sheet) {
		pageContent.push(await map_excel_to_pdf(desc_sheet));
	}

	const docDefinition = {
		pageOrientation: "landscape",
		pageSize: "A4",
		pageMargins: [20, 10, 20, 8],
		content: pageContent,
		defaultStyle: {
			font: "Roboto",
			fontSize: 8,
		},
	};

	pdfMake.createPdf(docDefinition).download(`${filename}.pdf`);
};

async function map_excel_to_pdf(worksheet) {
	let content = {};
	const tableBody = [];

	// hardcode logo place for all the sheets
	const logo_path = worksheet.getCell("A1").value;
	const company_logo = await frappe.xcall(
		"martin_bros.mb_projects.doctype.fwo.api.utils.get_image_dataurl",
		{ image_path: logo_path }
	);

	// Map to track merged cells
	const mergedCellsMap = new Map();
	Object.entries(worksheet._merges).forEach(([startCell, mergeData]) => {
		const { top, left, bottom, right } = mergeData.model;

		const masterCell = worksheet.getCell(startCell);
		mergedCellsMap.set(`${top}-${left}`, {
			value: masterCell.value || "",
			colSpan: right - left + 1,
			rowSpan: bottom - top + 1,
		});
	});

	// Find max columns
	let maxColumns = 0;
	worksheet.eachRow({ includeEmpty: true }, (row) => {
		maxColumns = Math.max(maxColumns, row.actualCellCount);
	});

	// Process rows with various properties like colspan, rowspan, border, alignment
	worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
		const pdfRow = [];

		for (let colIndex = 1; colIndex <= maxColumns + 1; colIndex++) {
			const cell = row.getCell(colIndex);
			const mergeKey = `${rowIndex}-${colIndex}`;
			let value = cell.value || "";
			let colSpan = 1;
			let rowSpan = 1;
			let borders = [];

			if (mergedCellsMap.has(mergeKey)) {
				const mergeData = mergedCellsMap.get(mergeKey);
				value = mergeData.value;
				colSpan = mergeData.colSpan;
				rowSpan = mergeData.rowSpan;
			}
			if (cell.style?.border) {
				borders = [
					cell.style.border.left ? 1 : 0,
					cell.style.border.top ? 1 : 0,
					cell.style.border.right ? 1 : 0,
					cell.style.border.bottom ? 1 : 0,
				];
			}

			const pdfCell = {
				text: value.toString(),
				colSpan: colSpan > 1 ? colSpan : undefined,
				rowSpan: rowSpan > 1 ? rowSpan : undefined,
				style: {},
				border: borders,
				noWrap: true,
			};

			if (rowIndex == 1 && colIndex == 1) {
				pdfCell.text = undefined;
				pdfCell.image = company_logo;
				pdfCell.width = 60;
				pdfCell.height = 60;
				delete pdfCell.text;
			}
			if (cell.style?.font?.bold) {
				pdfCell.style.bold = true;
			}
			if (cell.style?.alignment?.horizontal) {
				pdfCell.alignment = cell.style.alignment.horizontal;
			}
			if (cell.style?.fill?.fgColor?.argb) {
				pdfCell.fillColor = `#${cell.style.fill.fgColor.argb.substring(2)}`;
			}
			if (
				(cell.style?.alignment?.wrapText && worksheet.name == "FWO Description") ||
				(rowIndex == 11 && colIndex == 1 && worksheet.name != "FWO Description")
			) {
				pdfCell.noWrap = false;
			}

			pdfRow.push(pdfCell);
		}
		tableBody.push(pdfRow);
	});

	const colWidths = new Array(maxColumns + 1).fill("auto");
	content = {
		table: {
			widths: colWidths,
			body: tableBody,
		},
		layout: {
			defaultBorder: false,
			hLineColor: () => "#aaa",
			vLineColor: () => "#aaa",
		},
	};
	if (worksheet.name == "FWO Description") {
		content["pageBreak"] = "before";
	}
	return content;
}
