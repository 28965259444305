erpnext.TransactionController = class CustomTransactionController extends (
	erpnext.TransactionController
) {
	add_taxes_from_item_tax_template(item_tax_map) {
		let me = this;
		if (
			item_tax_map &&
			cint(frappe.defaults.get_default("add_taxes_from_item_tax_template"))
		) {
			if (typeof item_tax_map == "string") {
				item_tax_map = JSON.parse(item_tax_map);
			}
			$.each(item_tax_map, function (tax, rate) {
				let found = (me.frm.doc.taxes || []).find((d) => d.account_head === tax);
				if (!found) {
					let child = frappe.model.add_child(me.frm.doc, "taxes");
					child.charge_type = "On Net Total";
					child.account_head = tax;
					if (
						["Purchase Order", "Purchase Receipt", "Purchase Invoice"].includes(
							me.frm.doc.doctype
						)
					) {
						frappe.db.get_value("Account", tax, "account_type", (r) => {
							if (r.account_type == "Tax") {
								child.category = "Valuation and Total";
							}
						});
					}
					child.rate = 0;
				}
			});
		}
	}
	account_head(doc, cdt, cdn) {
		let row = locals[cdt][cdn];
		if (
			["Purchase Order", "Purchase Receipt", "Purchase Invoice"].includes(doc.doctype) &&
			row.account_head
		) {
			frappe.db.get_value("Account", row.account_head, "account_type", (r) => {
				if (r.account_type == "Tax") {
					frappe.model.set_value(cdt, cdn, "category", "Valuation and Total");
				}
			});
		}
	}
	custom_length(frm, cdt, cdn) {
		const row = locals[cdt][cdn];
		const len = martin_bros.utils.convert_length(row.custom_length);
		frappe.model.set_value(cdt, cdn, "qty", (len.total_inches * row.custom_quantity) / 12);
	}
	custom_quantity(frm, cdt, cdn) {
		const row = locals[cdt][cdn];
		const len = martin_bros.utils.convert_length(row.custom_length);
		frappe.model.set_value(cdt, cdn, "qty", (len.total_inches * row.custom_quantity) / 12);
	}
};
